import {globalConfig} from 'Config/config';
import {Queries} from 'GQL/GQLConstants';
import {gqlCall, GQLType} from 'GQL/utils';

const gqlLinkTransaction = async (payload, headers) => {
  const {
    BFF: {astBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.LINK_TRANSACTION_WITH_SR,
      payload,
      headers,
      astBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

export {gqlLinkTransaction};
