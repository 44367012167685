import React, {useEffect, useState} from 'react';
import Form from 'Components/Form/FormWrapper';
import {serviceRequestSummarySection} from './ServiceRequestSummarySR';
import {validationSchema, initialValues} from './serviceRequestSummarySchema';
import {useSelector} from 'react-redux';
import {filterServiceRequestTypeDTOS, updateFormContent} from 'Utils/helpers/SRDetailViewHelper';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {SR_STATUS} from 'Utils/constants/genericConstants';

interface IProps {
  srDetails?: any;
  setSRType: any;
  setServiceType: any;
  srType: any;
  serviceType: any;
  setSRSubType: any;
  setIsSubTypeMandatory: any;
  transactionJourney?: any;
  claimId?: any;
}
const ServiceRequestSummarySR: React.FC<IProps> = (props) => {
  const [formValues, setFormValues]: any = useState(initialValues);
  const [section1FormContent, setSection1FormContent] = useState(serviceRequestSummarySection);
  const reduxMapping = useSelector((state: any) => state.TenantServicesMappings.mapping);
  const mapping = props?.claimId ? filterServiceRequestTypeDTOS(reduxMapping) : reduxMapping;
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  let targetFields: any;

  useEffect(() => {
    updateServiceAndSRTypeList();
    updateFormValues();
  }, [reduxMapping]);

  useEffect(() => {
    if (selectedSRData && selectedSRData.additionalData) {
      updateSRTypeList(selectedSRData?.additionalData?.service);
      updateFormValues();

      targetFields = {
        srService: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        srType: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
      };

      let updatedFormContent = updateFormContent(section1FormContent, targetFields);
      setSection1FormContent(updatedFormContent);
    } else {
      targetFields = {
        srService: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        srType: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
      };

      let updatedFormContent = updateFormContent(section1FormContent, targetFields);
      setSection1FormContent(updatedFormContent);
    }
  }, [selectedSRData]);

  const updateFormValues = () => {
    const srId = selectedSRData?.id ? selectedSRData?.id : '';

    const srService = selectedSRData?.additionalData?.service
      ? selectedSRData?.additionalData?.service
      : mapping?.serviceTypes?.find((service) => service.name === 'General')
      ? 'General'
      : (mapping &&
          mapping.serviceTypes &&
          mapping.serviceTypes.length > 0 &&
          mapping.serviceTypes[0]) ||
        '';

    let srTypes = (
      mapping?.serviceTypes?.find((service) => service.name === srService)
        ?.serviceRequestTypeDTOS || []
    ).map((srType) => srType.name);
    const srType = selectedSRData?.type ? selectedSRData?.type : getSRType(srTypes);
    let closedDate = '';
    if ((selectedSRData?.status || '').toLowerCase() === SR_STATUS.CLOSED.toLowerCase()) {
      closedDate = dateConversionIsoToLocal(
        selectedSRData?.audit?.modifiedOn,
        FORMAT_TYPE.DATE_TIME,
        '',
        tenantConfig.locale,
      );
    }
    if (srId) {
      let targetFormFields = {
        srType: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
      };
      let updatedFormContent = updateFormContent(section1FormContent, targetFormFields);
      setSection1FormContent(updatedFormContent);
    }

    const selectedSRValues = {
      srService: srService,
      srStatus: selectedSRData?.status,
      srType: srType || '',
      srClosedDate: closedDate,
      srId: srId,
      srCreatedDate: dateConversionIsoToLocal(
        selectedSRData?.audit?.createdOn,
        FORMAT_TYPE.DATE_TIME,
        '',
        tenantConfig.locale,
      ),
      srCreatedBy: selectedSRData?.audit?.createdBy,
      srModifiedDate: dateConversionIsoToLocal(
        selectedSRData?.audit?.modifiedOn,
        FORMAT_TYPE.DATE_TIME,
        '',
        tenantConfig.locale,
      ),
      srModifiedBy: selectedSRData?.audit?.modifiedBy,
    };
    setFormValues(selectedSRValues);
    setSRSubTypesAndMandatoryConstraint(srType);
    props.setServiceType(srService);
    props.setSRType(srType || '');
  };

  const updateServiceAndSRTypeList = () => {
    let srTypes = (
      mapping?.serviceTypes?.find(
        (service) => service.name === (selectedSRData?.additionalData?.service || 'General'),
      )?.serviceRequestTypeDTOS || []
    ).map((srType) => {
      return {
        value: srType.name,
        text: srType.name,
      };
    });

    let targetFields = {
      srService: {
        targetAttribute: 'options',
        targetValue: (mapping?.serviceTypes || []).map((serviceType) => {
          return {
            value: serviceType.name,
            text: serviceType.name,
          };
        }),
      },
      srType: {
        targetAttribute: 'options',
        targetValue: srTypes,
      },
    };

    let updatedFormContent = updateFormContent(section1FormContent, targetFields);
    setSection1FormContent(updatedFormContent);
  };

  const updateSRTypeList = (serviceName) => {
    if (!serviceName) {
      serviceName = 'General';
    }
    let srTypes = (
      mapping?.serviceTypes?.find((service) => service.name === serviceName)
        ?.serviceRequestTypeDTOS || []
    ).map((srType) => {
      return {
        value: srType.name,
        text: srType.name,
      };
    });
    let targetFields = {
      srType: {
        targetAttribute: 'options',
        targetValue: srTypes,
      },
    };
    let updatedFormContent = updateFormContent(section1FormContent, targetFields);
    setSection1FormContent(updatedFormContent);
  };

  const getSRType = (srTypes) => {
    if (srTypes.find((srType) => srType?.toUpperCase() === 'Membership Services'.toUpperCase())) {
      return 'Membership Services';
    }
    return srTypes[0];
  };

  const updateSelectedForm = (serviceName) => {
    if (!mapping || !mapping.serviceTypes) {
      return;
    }
    let srTypes = (
      mapping.serviceTypes?.find((service) => service.name === serviceName)
        ?.serviceRequestTypeDTOS || []
    ).map((srType) => srType.name);

    let selectedSRType = getSRType(srTypes);
    setFormValues({
      srService: serviceName,
      srType: selectedSRType,
    });
    setSRSubTypesAndMandatoryConstraint(selectedSRType);
    props.setSRType(selectedSRType);
  };

  const setSRSubTypesAndMandatoryConstraint = (srType) => {
    try {
      let service = mapping?.serviceTypes?.find((service) =>
        service?.serviceRequestTypeDTOS?.find((sr) => sr.name === srType),
      );
      let sr = service?.serviceRequestTypeDTOS?.find((sr) => sr.name === srType);
      let subTypes = sr?.allowedSubTypes || [];
      props.setIsSubTypeMandatory(sr?.isSubTypeMandatory || false);
      props.setSRSubType(subTypes);
    } catch (err) {
      console.log(err);
    }
  };

  const onSRTypeChangeEvent = (e) => {
    let srTypeValue = e.target.value;
    setFormValues({
      srService: formValues.srService,
      srType: srTypeValue,
    });
    setSRSubTypesAndMandatoryConstraint(srTypeValue);
    props.setSRType(srTypeValue);
  };

  const onServiceChange = (e) => {
    let service = e.target.value;
    props.setServiceType(service);
    updateSRTypeList(service); //update the type dropdown on service selection
    updateSelectedForm(service); //update initial value
  };

  const customEvents = {
    srType: {onChange: onSRTypeChangeEvent},
    srService: {onChange: onServiceChange},
  };
  const handleOnSubmit = () => {};

  return (
    <Form
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
      initialValues={formValues}
      section1={section1FormContent}
      customEvents={customEvents}
    />
  );
};
export default ServiceRequestSummarySR;
