import {configureStore, combineReducers} from '@reduxjs/toolkit';
import memberReducer from 'Redux/Slices/member';
import loginReducer from 'Redux/Slices/login';
import selectedMemberReducer from 'Redux/Slices/selectedMember';
import RecentSRList from 'Redux/Slices/RecentSRList';
import archivedSRList from 'Redux/Slices/ArchivedSRList';
import cashbackSRList from 'Redux/Slices/CashbackSRList';
import selectedSR from 'Redux/Slices/selectedSR';
import TenantServicesMappings from 'Redux/Slices/TenantServicesMappings';
import serviceRequest from 'Redux/Slices/serviceRequest';
import allMetadata from 'Redux/Slices/allMetadata';
import impersonateToken from 'Redux/Slices/impersonateToken';
import gmOrderHistory from 'Redux/Slices/gmGiftingHistory';
import countries from 'Redux/Slices/countries';
import gmMemmberRewardsInfo from 'Redux/Slices/gmMemberRewards';
import SRListFilter from 'Redux/Slices/SRListFilter';
import event from 'Redux/Slices/eventList';
import transactionList from 'Redux/Slices/transactionDashboardList';

const appReducer = combineReducers({
  login: loginReducer,
  member: memberReducer,
  selectedMember: selectedMemberReducer,
  recentSRList: RecentSRList,
  archivedSRList: archivedSRList,
  cashbackSRList: cashbackSRList,
  selectedSR: selectedSR,
  serviceRequest: serviceRequest,
  TenantServicesMappings: TenantServicesMappings,
  getAllMetadata: allMetadata,
  impersonateToken: impersonateToken,
  gmOrderHistory: gmOrderHistory,
  countries: countries,
  gmMemmberRewardsInfo: gmMemmberRewardsInfo,
  SRListFilter: SRListFilter,
  event: event,
  transactionLogList: transactionList,
});

export const rootReducer = (state, action) => {
  if (action.type === 'resetStore') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export default store as any;
