import {DialogContent} from '@mui/material';
import DialogView from 'Components/DialogView';
import Form from 'Components/Form/FormWrapper';
import SnackBar from 'Components/SnackBar';
import TableView from 'Components/TableView';
import {useSnackBar} from 'Hooks';
import {gqlPaymentCardDetails, gqlCreatePayments} from 'GQL/MethodsBFF/Payment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCountries, selectCountries} from 'Redux/Slices/countries';
import {MASTERCARD_REGEX, VISA_REGEX} from 'Utils/constants/genericConstants';
import {SortOrder} from 'Utils/enums';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {cardNumberFormatter, getCountriesOptionValue} from 'Utils/helpers/PaymentHelper';
import {updateFormContent} from 'Utils/helpers/SRDetailViewHelper';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import {cardSection1, buttonList} from './Card';
import {getCardGridData, mapTableData} from './CardLookupGrid';
import {createValidationSchema, initialValues} from './CardSchema';
import {isContainsNumbers} from 'Utils/helpers/Numeric';
import {fetchMemberDetails} from 'Redux/Slices/selectedMember';
import Loading from 'Components/Loading';

interface IProps {
  openCardLookup?: any;
  isOpenCardLookup?: any;
  setSelectedCard: (data) => void;
  handleLookupClose?: any;
}

const CardLookup: React.FC<IProps> = (props) => {
  const {openCardLookup, isOpenCardLookup} = props;
  const selectedSR = useSelector((state: any) => state.selectedSR?.getServiceRequestById);
  let memberDetails = useSelector((state: any) => state?.selectedMember?.selectedMember);
  const loginDetails = useSelector((state: any) => state?.login);
  let currentMemberDetails = memberDetails?.currentMemberDetails;
  const countries: any = useSelector(selectCountries);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(SortOrder.DESCENDING);
  const [paymentVehiclesDetails, setPaymentVehiclesDetails] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<any>(initialValues);
  const [cardSection1FormContent, setCardSection1FormContent] = useState(cardSection1);
  const [currentSortCriteria, setCriteriaSort] = useState('cardNumber');
  const [tableData, setTableData] = useState<any[]>([]);
  const [saveForLater, setSaveForLater] = useState(false);
  const [isMemberAddressBillingAddress, setIsMemberAddressBillingAddress] = useState(false);
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    populateAllCountries();
    setError('');
    setFormValues(initialValues);
  }, []);

  useEffect(() => {
    disableEnableFormFieldInEdit(false);
  }, [openCardLookup]);

  const populateCountryValue = (countryArray) => {
    let targetField = {
      country: {
        targetAttribute: 'options',
        targetValue: getCountriesOptionValue(countryArray),
      },
    };
    let updatedFormContent = updateFormContent(cardSection1, targetField);
    setCardSection1FormContent(updatedFormContent);
  };

  const populateAllCountries = async () => {
    let countryArray = [];
    if (countries.length >= 0) {
      let resp = await dispatch(fetchCountries({})).unwrap();
      if (resp?.data?.getCountries) {
        countryArray = resp?.data?.getCountries;
      }
    } else {
      countryArray = countries;
    }
    populateCountryValue(countryArray);
  };

  useEffect(() => {
    populateCardDetails();
    setFormValues({
      ...initialValues,
      isMemberAddressBillingAddress: false,
      saveForLater: false,
    });
    setIsMemberAddressBillingAddress(false);
    setSaveForLater(false);
  }, [currentMemberDetails]);

  const populateCardDetails = (newpaymentVehicles?) => {
    if (currentMemberDetails) {
      let paymentVehicles = newpaymentVehicles
        ? newpaymentVehicles
        : currentMemberDetails?.paymentVehicles;
      if (paymentVehicles?.length) {
        let pvIdList: any = [];
        paymentVehicles.map((item) => {
          if (item.type === 'credit-card') {
            pvIdList.push(item.pvId);
          }
          return null;
        });
        pvIdList && pvIdList.length > 0 && getPaymentVehiclesDetails(pvIdList);
      }
    }
  };

  const getPaymentVehicles = async (paymentVehiclesList) => {
    try {
      const headers = {
        'tenant-id': loginDetails?.tenantID,
        'user-access-token': loginDetails?.loginInfo?.access_token,
        'client-access-token': loginDetails?.loginInfo?.service_token,
      };
      const cardDetails = await gqlPaymentCardDetails(
        {
          idList: paymentVehiclesList,
        },
        headers,
      );
      if (cardDetails.error) {
        setError(cardDetails.error.message);
      }
      let memberPaymentVehicleDetails =
        cardDetails &&
        cardDetails.data &&
        cardDetails.data.getPaymentVehiclesDetails &&
        cardDetails.data.getPaymentVehiclesDetails.paymentVehiclesDetails;

      return memberPaymentVehicleDetails;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const getPaymentVehiclesDetails = async (paymentVehiclesList) => {
    const paymentDetails = await getPaymentVehicles(paymentVehiclesList);
    setPaymentVehiclesDetails(paymentDetails);
    let tableData = mapTableData(paymentDetails, selectedSR);
    let resp = sortTable(
      tableData,
      'cardCreatedDate',
      '',
      sortOrder,
      loginDetails?.tenantConfig?.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  const handleCardSelection = (rowData) => {
    if (rowData.status === 'EXPIRED') {
      handleUpdate(rowData);
    } else {
      props.setSelectedCard(rowData);
    }
  };

  const populateUnsavedCardDetails = (values, expiryDateYear, expiryDateMonth, newPaymentId) => {
    const cardDetails = {
      cardNumber: cardNumberFormatter(values.cardNumber),
      cardType: values.cardType,
      expiryDate: `${expiryDateMonth}-${expiryDateYear}`,
      nameOnCard: values.nameOnCard,
      pvId: newPaymentId,
      status: 'ACTIVE',
    };
    handleCardSelection(cardDetails);
  };

  const removeUnwantedSpaces = (nameOnCard) => {
    nameOnCard = nameOnCard.trim();
    let nameArr = nameOnCard.split(' ').filter((name) => Boolean(name) === true);
    return nameArr.join(' ');
  };

  const handleSave = async (values, action) => {
    setLoading(true);
    let newDate: any = dateConversionIsoToLocal(values.expiryDate);
    let date = newDate.split('/');
    let expiryDateYear = date[2];
    let expiryDateMonth = date[1];
    const headers = {
      'tenant-id': loginDetails?.tenantID,
      'user-access-token': loginDetails?.loginInfo?.access_token,
      'client-access-token': loginDetails?.loginInfo?.service_token,
    };

    try {
      const paymentIdAfterCardAddition = await gqlCreatePayments(
        {
          paymentMethod: 'CREDITCARD',
          billingAddress: {
            city: values.city,
            postalCode: values.postalCode,
            addressLine3: values.addressLineThree,
            state: currentMemberDetails && currentMemberDetails.state,
            addressLine2: values.addressLineTwo,
            countryCode: values.country,
            county: currentMemberDetails && currentMemberDetails.county,
            addressLine1: values.addressLineOne,
            email: currentMemberDetails?.primaryEmail || currentMemberDetails?.clientEmail,
          },
          membershipId: memberDetails && memberDetails.membershipId,
          memberId: currentMemberDetails && currentMemberDetails.memberId,
          userId: currentMemberDetails && currentMemberDetails?.userId,
          cardDetails: {
            nameOnCard: removeUnwantedSpaces(values.nameOnCard),
            cardNumber: values.cardNumber,
            expirationYear: expiryDateYear,
            expirationMonth: expiryDateMonth,
            paymentMethodType: values.cardType,
          },
          primaryMemberExternalRefId: currentMemberDetails && currentMemberDetails.externalRefId,
          isDefault: false,
          saveForLater: Boolean(saveForLater) ? true : false,
          isMemberAddressBillingAddress: Boolean(isMemberAddressBillingAddress) ? true : false,
        },
        headers,
      );

      if (paymentIdAfterCardAddition?.error) {
        let errors = paymentIdAfterCardAddition?.error?.graphQLErrors[0].extensions.errors;
        setError(errors[0].message);
      }
      let newPaymentId =
        paymentIdAfterCardAddition &&
        paymentIdAfterCardAddition.data.createPaymentDetails.paymentVehicleId;
      if (newPaymentId && saveForLater) {
        callMemberDetailsApi();
        let selectedMember = Object.assign({}, memberDetails);
        let paymentVehicles = selectedMember?.currentMemberDetails?.paymentVehicles;
        if (saveForLater) {
          paymentVehicles = paymentVehicles.map((item) => ({
            ...item,
            isDefault: false,
          }));
        }
        paymentVehicles.push({
          pvId: newPaymentId,
          type: 'credit-card',
          isDefault: Boolean(saveForLater) ? true : false,
          isMemberAddressBillingAddress: Boolean(isMemberAddressBillingAddress) ? true : false,
        });
        if (isEditMode) {
          setIsEditMode(false);
          const variable = {
            memberId: currentMemberDetails.memberId,
            membershipId: selectedMember.membershipId ?? currentMemberDetails.memberId,
          };
          await dispatch(fetchMemberDetails({variable})).unwrap();
        }
        populateCardDetails(paymentVehicles);
      } else if (newPaymentId && !saveForLater) {
        populateUnsavedCardDetails(values, expiryDateYear, expiryDateMonth, newPaymentId);
      } else console.log('Something went Wrong!!');
      setLoading(false);
      disableEnableFormFieldInEdit(false);
      action.resetForm();
    } catch (err) {
      console.log(err);
    }
  };

  const callMemberDetailsApi = () => {
    let selectedMember = Object.assign({}, memberDetails);
    const variable = {
      memberId: currentMemberDetails?.memberId,
      membershipId: selectedMember?.membershipId ?? currentMemberDetails?.memberId,
    };
    memberDetailsApiCall(variable);
  };

  const memberDetailsApiCall = async (variable) => {
    try {
      memberDetails = await dispatch(fetchMemberDetails({variable})).unwrap().selectedMember;
      currentMemberDetails = memberDetails.currentMemberDetail;
    } catch (error: any) {
      console.log(error);
    }
  };

  const onSubmit = (values, action) => {
    if (values?.buttonAction) {
      switch (values?.buttonAction) {
        case 'Save':
          handleSave(values, action);
          break;
        case 'Reset':
          action.resetForm();
          break;
      }
    }
  };

  const onSortIconClick = (sortCriteria: string) => {
    let resp = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      loginDetails?.tenantConfig?.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  const setMemberAddress = (e, setField) => {
    if (e.target.checked) {
      setField('addressLineOne', currentMemberDetails?.addressLine1 || '');
      setField('addressLineTwo', currentMemberDetails?.addressLine2 || '');
      setField('addressLineThree', currentMemberDetails?.addressLine3 || '');
      setField('city', currentMemberDetails?.city || '');
      setField('postalCode', currentMemberDetails?.postalCode || '');
      setField('country', currentMemberDetails?.countryCode || '');
      setField(
        'nameOnCard',
        currentMemberDetails?.firstName + ' ' + currentMemberDetails?.lastName || '',
      );
    } else {
      setField('addressLineOne', '');
      setField('addressLineTwo', '');
      setField('addressLineThree', '');
      setField('city', '');
      setField('postalCode', '');
      setField('country', '');
      setField('nameOnCard', '');
    }
    setIsMemberAddressBillingAddress(e.target.checked);
  };

  const onCardNumberChange = function (e, setField) {
    let cardNumber = e.target.value;
    if (cardNumber.match(VISA_REGEX)) {
      setField('cardType', 'Visa');
    } else if (cardNumber.match(MASTERCARD_REGEX)) {
      setField('cardType', 'Mastercard');
    } else {
      setField('cardType', '');
    }
  };

  const handleSaveForLater = (e) => {
    if (e.target.checked) {
      setSaveForLater(e.target.checked);
    } else {
      setSaveForLater(false);
    }
  };

  const onNameOnCardChange = (e, setField) => {
    let tempName = e.target.value;
    if (isContainsNumbers(tempName)) {
      tempName = tempName.split(/\d/g).join('');
    }
    setField('nameOnCard', tempName);
  };

  const disableEnableFormFieldInEdit = (isDisable) => {
    let targetField = {
      cardNumber: {
        targetAttribute: 'isDisabled',
        targetValue: isDisable,
      },
      saveForLater: {
        targetAttribute: 'isDisabled',
        targetValue: isDisable,
      },
      nameOnCard: {
        targetAttribute: 'isDisabled',
        targetValue: isDisable,
      },
    };
    let updatedFormContent = updateFormContent(cardSection1, targetField);
    setCardSection1FormContent(updatedFormContent);
  };
  const handleUpdate = (data) => {
    disableEnableFormFieldInEdit(true);
    let selectedPvDetails = paymentVehiclesDetails.find(
      (pvData) => pvData.paymentVehicleId === data.pvId,
    );
    setFormValues({
      cardNumber: selectedPvDetails.paymentMethodDetails.cardNumber,
      cardType: data.cardType,
      expiryDate: data.expiryDate,
      nameOnCard: data.nameOnCard,
      saveForLater: true,
      addressLineOne: selectedPvDetails.billingAddress.addressLine1,
      addressLineTwo: selectedPvDetails.billingAddress.addressLine2,
      addressLineThree: selectedPvDetails.billingAddress.addressLine3,
      city: selectedPvDetails.billingAddress.city,
      postCode: selectedPvDetails.billingAddress.postalCode,
      country: selectedPvDetails.billingAddress.countryCode,
    });
    setSaveForLater(true);
  };
  const customEvents = {
    cardNumber: {onChange: onCardNumberChange},
    nameOnCard: {onChange: onNameOnCardChange},
    isMemberAddressBillingAddress: {onChange: setMemberAddress},
    saveForLater: {onChange: handleSaveForLater},
  };

  const tableViewCustomEvents = {
    updateButton: (data) => {
      setIsEditMode(true);
      handleUpdate(data);
    },
  };

  // const onReset = () => {
  //   setSaveForLater(false);
  //   //setValidFields(initialValidState);
  //   setFormValues({
  //     ...initialValues,
  //     nameOnCard: `${currentMemberDetails?.firstName || ''} ${
  //       currentMemberDetails?.lastName || ''
  //     }`,
  //     useMemberAddress: false,
  //     saveForLater: false,
  //   });
  //   //resetFieldsBorder();
  // };

  // const showErrors = (errors) => {
  //   let targetFormFields = {
  //     paymentcardreset: {
  //       targetAttribute: 'errors',
  //       targetValue: errors,
  //     },
  //   };

  //   let newFormContent = updateFormContent(cardSection1FormContent, targetFormFields);
  //   setCardSection1FormContent(newFormContent);
  // };

  const cardButtonList = [
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'updateButton',
      id: 'updateButton',
      fieldLabel: 'edit',
      variant: 'contained',
      wrapperClass: '',
      dataTest: 'updateButton',
      xs: 2,
      sm: 2,
    },
  ];

  return (
    <DialogView closeDialogMethod={isOpenCardLookup} open={openCardLookup} id="card-lookup-dialog">
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        {loading && <Loading />}
        <Form
          onSubmit={onSubmit}
          initialValues={formValues}
          section1={cardSection1FormContent}
          buttonStack={buttonList}
          customEvents={customEvents}
          validationSchema={createValidationSchema(paymentVehiclesDetails)}
        />
        <TableView
          data-test={'grid'}
          {...getCardGridData(tableData, sortOrder, currentSortCriteria, true)}
          buttons={cardButtonList}
          customEvents={tableViewCustomEvents}
          onRowClick={handleCardSelection}
          sortTable={onSortIconClick}
        />
      </DialogContent>
      <SnackBar
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
        errorMessage={error}
      />
    </DialogView>
  );
};

export default CardLookup;
