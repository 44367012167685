import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'; //createSelector,
import {gqlTransactionDashboardSearch} from 'GQL/MethodsBFF/ServiceRequest';
import {v4 as uuidv4} from 'uuid';

interface TRList {
  id: any;
}

const trListAdapter: any = createEntityAdapter<TRList>({
  selectId: (trData: TRList) => trData.id,
});

const initialState = trListAdapter.getInitialState({
  status: 'idle',
  error: '',
});

export const getAllClaimedOffer: any = createAsyncThunk(
  'serviceRequest/getAllClaimedOffer',
  async (data: any, {getState}) => {
    const state: any = getState();
    const headers = {
      //'tenant-id': state?.login.tenantID,
      'tenant-id': 5000005,
      'x-correlation-id': uuidv4(),
      Authorization: `Bearer ${state?.login.loginInfo?.access_token}`,
    };
    return await gqlTransactionDashboardSearch(data, headers);
  },
);

const getAllClaimedOfferSlice = createSlice({
  name: 'getAllClaimedOffer',
  initialState,
  reducers: {
    resetGetAllClaimedOfferData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllClaimedOffer.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllClaimedOffer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.transactionslogList = action?.payload?.data?.getAllClaimedOffer?.[0];
      })
      .addCase(getAllClaimedOffer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default getAllClaimedOfferSlice.reducer;

// export const {selectAll: selectAllMember} = trListAdapter.getSelectors((state) => state.member);

export const {resetGetAllClaimedOfferData} = getAllClaimedOfferSlice.actions;
