import {parseData} from 'Utils/helpers/SRListHelpers';
import {SortOrder} from 'Utils/enums';

export const getEventssGirdData = (
  tableData,
  sortOrder = SortOrder.DESCENDING,
  currentSortCriteria,
  isSortingEnabled = true,
) => {
  const header = [
    {label: 'Event Name', value: 'eventName'},
    {label: 'Source', value: 'source'},
    {label: 'Event Id', value: 'eventId'},
  ];

  let hideColumns = {};

  return parseData(
    header,
    tableData,
    sortOrder,
    currentSortCriteria,
    isSortingEnabled,
    hideColumns,
  );
};
