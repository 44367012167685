export enum SortOrder {
  ASCENDING,
  DESCENDING,
}

export enum SRPageSize {
  CASHBACK = 100,
  RECENT = 100,
  ARCHIVE = 100,
}

export enum TabPanelType {
  subscription = '0',
  benefits = '1',
  memberProfile = '2',
  adminMemberProfile = '3',
  serviceRequests = '4',
  transaction = '5',
  reissuance = '6',
  reissuanceHistory = '7',
  queryForm = '8',
  giftCardCatalog = '9',
  giftingHistory = '10',
  events = '11',
  transactionLogs = '12',
}
