import {Box, Button, DialogContent, Typography} from '@mui/material';
import DialogView from 'Components/DialogView';
import React, {useState, useEffect, useRef} from 'react';
import SRFormSelector from '../../SRForms/SRFormSelector';
import ServiceRequestSummarySR from '../ServiceRequestSummary';
import globalSpacing from 'Theme/Spacing';
import {useSelector} from 'react-redux';
import {gqlOwnerDetails} from 'GQL/MethodsBFF/ServiceRequest';
import Payment from '../Payment';
import Activities from '../Activities';

interface IProps {
  openSRDetailViewDialogDialog: boolean;
  isOenSRDetailViewDialogDialog: any;
  claimId?: any;
}

const SRDetailViewDialog: React.FC<IProps> = (props) => {
  const {openSRDetailViewDialogDialog, isOenSRDetailViewDialogDialog, claimId} = props;
  const accessToken = useSelector((state: any) => state?.login.loginInfo?.access_token);
  const serviceToken = useSelector((state: any) => state?.login.loginInfo?.service_token);

  const [srType, setSRType] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [srSubType, setSRSubTypes]: any = useState([]);
  const [srOwnerList, setOwnerList] = useState([]);
  const [isSubTypeMandatory, setIsSubTypeMandatory] = useState(false);
  const activityRef = useRef<any>();
  const paymentRef = useRef<any>();

  useEffect(() => {
    (async () => {
      try {
        let headers = {
          'tenant-id': 'internal',
          'user-access-token': accessToken,
          'client-access-token': serviceToken,
        };
        let response = await gqlOwnerDetails(headers);
        if (response.error) {
          setOwnerList([]);
        } else {
          setOwnerList(response);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const executeActivityScroll = () => {
    if (!activityRef.current) return;
    activityRef.current.scrollIntoView({behavior: 'smooth'});
  };
  const executePaymentScroll = () => {
    if (!paymentRef.current) return;
    paymentRef.current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <DialogView
      closeDialogMethod={isOenSRDetailViewDialogDialog}
      open={openSRDetailViewDialogDialog}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Box>
          <Button
            onClick={executeActivityScroll}
            variant="contained"
            sx={{
              paddingY: globalSpacing.xxs,
              marginY: globalSpacing.sm,
            }}>
            <Typography variant="body2" sx={{textTransform: 'none'}}>
              {'Activity'}
            </Typography>
          </Button>
          <Button
            onClick={executePaymentScroll}
            variant="contained"
            sx={{
              paddingY: globalSpacing.xxs,
              marginY: globalSpacing.sm,
              marginLeft: globalSpacing.xxs,
            }}>
            <Typography variant="body2" sx={{textTransform: 'none'}}>
              {'Payment'}
            </Typography>
          </Button>
        </Box>
        <ServiceRequestSummarySR
          srDetails={{
            srType,
            serviceType,
            srSubType,
            srOwnerList: srOwnerList,
            isSubTypeMandatory,
          }}
          srType={srType}
          serviceType={serviceType}
          setSRType={setSRType}
          setServiceType={setServiceType}
          setSRSubType={setSRSubTypes}
          setIsSubTypeMandatory={setIsSubTypeMandatory}
          claimId={claimId}
        />
        <SRFormSelector
          srDetails={{
            srType,
            serviceType,
            srSubType,
            srOwnerList: srOwnerList,
            isSubTypeMandatory,
            claimId,
          }}
          closeDialogMethod={isOenSRDetailViewDialogDialog}
        />
        <Payment ref={paymentRef} />
        <Activities ref={activityRef} />
      </DialogContent>
    </DialogView>
  );
};

export default SRDetailViewDialog;
