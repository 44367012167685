import React, {useState} from 'react';
import {CSVLink} from 'react-csv';
import {flatten} from 'flat';
import {Button, TextField, Autocomplete} from '@mui/material';

const JsonToCsv = ({jsonData}) => {
  const [flattenedJson, setFlattenedJson] = useState<any[]>([]);
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredHeaders, setFilteredHeaders] = useState<string[]>([]);
  const [allHeaders, setAllHeaders] = useState<string[]>([]);

  const handleFlatten = () => {
    const flatJson = jsonData.map((item) => flatten(item));
    setFlattenedJson(flatJson);
    const allKeys = flatJson.reduce((keys, currentItem) => {
      return [...new Set([...keys, ...Object.keys(currentItem)])];
    }, []);
    setAllHeaders(allKeys);
  };

  const handleSearch = () => {
    const filtered = allHeaders.filter((header) =>
      header.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredHeaders(filtered);
  };

  return (
    <div>
      <Button onClick={handleFlatten}>Download CSV</Button>
      {flattenedJson.length > 0 && (
        <div>
          <Autocomplete
            multiple
            value={selectedHeaders}
            onChange={(event, value) => {
              setSelectedHeaders(value);
              setFilteredHeaders([]);
            }}
            options={filteredHeaders.length > 0 ? filteredHeaders : allHeaders}
            onInputChange={(event, value) => {
              setSearchQuery(value);
              handleSearch();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search headers"
                variant="outlined"
                style={{width: '100%'}}
                InputLabelProps={{
                  style: {color: 'black'},
                }}
              />
            )}
          />

          <Button onClick={() => setSelectedHeaders(allHeaders)}>Select All</Button>
          <Button onClick={() => setSelectedHeaders([])}>Deselect All</Button>
        </div>
      )}

      {selectedHeaders.length > 0 && (
        <CSVLink
          data={flattenedJson.map((row) => {
            const newRow = {};
            selectedHeaders.forEach((header) => {
              newRow[header] = row[header];
            });
            return newRow;
          })}
          headers={selectedHeaders.map((header) => ({label: header, key: String(header)}))}
          filename="data.csv">
          <Button>Download</Button>
        </CSVLink>
      )}
    </div>
  );
};

export default JsonToCsv;
