import {parseData} from 'Utils/helpers/SRListHelpers';

export const getTRDashboardListGirdData = (
  tableData,
  sortOrder,
  currentSortCriteria,
  isSortingEnabled,
) => {
  const header = [
    {label: 'Membership #', value: 'membership'},
    {label: 'Transaction id', value: 'id'},
    {label: 'Transaction Type', value: 'claimType'},
    {label: 'Transaction Status', value: 'status'},
    {label: 'Purchased Date', value: 'claimDateTime'},
    {label: 'Offer Type', value: 'offerType'},
  ];

  let hideColumns = {
    transactionData: true,
  };

  return parseData(
    header,
    tableData,
    sortOrder,
    currentSortCriteria,
    isSortingEnabled,
    hideColumns,
  );
};
