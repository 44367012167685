import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
} from '@mui/material';
import {v4 as uuidv4} from 'uuid';
import {useDispatch, useSelector} from 'react-redux';
import {gqlTransactionDashboardSearch} from 'GQL/MethodsBFF/ServiceRequest';
import {CustomTextInput} from 'Components';
import {gqlLinkTransaction} from 'GQL/MethodsBFF/LinkTransaction';
import {searchSRByID} from 'Redux/Slices/selectedSR';

interface LinkTransactionDialogProps {
  open: boolean;
  onClose: () => void;
  selectedSRId?: string;
}

const LinkTransactionDialog: React.FC<LinkTransactionDialogProps> = (props) => {
  const {open, onClose, selectedSRId} = props;
  const [claimId, setClaimId] = useState('');
  const [bookingReference, setBookingReference] = useState('');
  const loginDetails = useSelector((state: any) => state?.login);
  const [transactions, setTransactions] = useState<any>(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedTransactionId(null);
    setTransactions(null);
    setClaimId('');
  }, [onClose]);

  const handleLinkTransaction = async () => {
    let payload = {
      srId: selectedSRId,
      claimId: selectedTransactionId,
    };
    const headers = {
      'tenant-id': loginDetails?.tenantID,
      // 'tenant-id': 5000005,
      'x-correlation-id': uuidv4(),
      Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
    };
    await gqlLinkTransaction(payload, headers);
    await dispatch(
      searchSRByID({
        id: selectedSRId,
      }),
    ).unwrap();

    onClose();
  };

  const handleSearch = async () => {
    let payload = {
      tenantId: loginDetails?.tenantID,
      claimId: claimId === '' ? null : claimId,
      bookingReference: bookingReference || '',
    };
    const headers = {
      'tenant-id': loginDetails?.tenantID,
      // 'tenant-id': 5000005,
      'x-correlation-id': uuidv4(),
      Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
    };
    let response = await gqlTransactionDashboardSearch(payload, headers);
    console.log(response.data.getAllClaimedOffer[0].claimedOffers);
    if (response.data.getAllClaimedOffer[0].claimedOffers) {
      setTransactions(response.data.getAllClaimedOffer[0].claimedOffers);
    }
  };
  const isSearchDisabled = !claimId && !bookingReference;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          minHeight: '70%',
        },
      }}>
      <DialogTitle>Link Transaction</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Please enter the Claim ID or Booking Reference to link the transaction.
        </Typography>
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            marginTop: '20px',
          }}>
          <CustomTextInput
            label="Claim ID"
            name="claimId"
            inline
            onChange={(e) => setClaimId(e.target.value)}
            value={claimId}
          />

          <CustomTextInput
            label="Booking Reference"
            name="bookingReference"
            inline
            onChange={(e) => setBookingReference(e.target.value)}
            value={bookingReference}
          />
        </Box>

        {transactions && transactions?.length ? (
          <Box sx={{marginTop: '20px'}}>
            <Typography variant="h6">Available Transactions:</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedTransactionId}
                onChange={(e) => setSelectedTransactionId(e.target.value)}>
                {transactions?.map((transaction) => (
                  <FormControlLabel
                    key={transaction.id}
                    control={<Radio sx={{color: theme.palette.text.primary}} />}
                    label={`Transaction ID: ${transaction.id} - ${transaction.offerType} - ${transaction.status}`}
                    value={transaction.id}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSearch} color="primary" disabled={isSearchDisabled}>
          Search
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleLinkTransaction} color="primary" disabled={!selectedTransactionId}>
          Link Transaction
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkTransactionDialog;
