import globalSpacing from '../../../Theme/Spacing';

const styles = {
  // container: {
  //   flexDirection: 'column',
  //   display: 'flex',
  // },
  paper: {
    marginTop: globalSpacing.sm,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '10px',
    // border: '1px solid black',
    '&::-webkit-scrollbar': {
      width: '0.3rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgrey',
      borderRadius: 15,
    },
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)' /* Creates 5 equal columns */,
    maxHeight: '100%',
    gap: '7px' /* Add spacing between grid items */,
    // justifyItems: 'center' /* Centers the grid items horizontally */,
    alignItems: 'center' /* Centers the grid items vertically */,
    '@media (max-width:1300px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: globalSpacing.sm,
  },
  fieldsContainer: {display: 'flex', flexWrap: 'wrap', gap: '5%'},
};

export default styles;
