import React, {Fragment, useState, useEffect} from 'react';
import MembershipServiceSR from './MembershipServicesSR';
import BookCashbackEventSR from './BookCashbackEventSR';
import ComplaintSR from './ComplaintSR';
// import CinemaECodeSR from './CinemaECodeSR';
import BookEventSR from './BookEventSR';
import EnquirySR from './EnquirySR';
import {enquirySRTypes, linkTransactionSRTypes} from 'Utils/constants/genericConstants';
import CommentAndQuerySR from './CommentAndQuerySR';

interface IProps {
  toggleView?: any;
  srDetails?: any;
  closeDialogMethod?: any;
}

const SRFormSelector: React.FC<IProps> = (props) => {
  const initialState = {
    srType: 'default',
    service: '',
    srSubType: [],
    srOwnerList: [],
    isSubTypeMandatory: false,
  };

  const [srDetails, setsrDetails] = useState(initialState);

  useEffect(() => {
    setsrDetails(props.srDetails);
  }, [props.srDetails]);

  const renderForms = () => {
    if (srDetails?.srType && typeMapping[srDetails?.srType]) return typeMapping[srDetails.srType]();
    if (enquirySRTypes?.includes(srDetails?.srType)) return typeMapping['Enquiry']();
    if (linkTransactionSRTypes.includes(srDetails?.srType)) return typeMapping['CommentAndQuery']();
    else return typeMapping['default']();
  };

  const renderMembershipServicesSR = () => {
    return (
      <MembershipServiceSR srFormDetails={srDetails} closeDialogMethod={props.closeDialogMethod} />
    );
  };

  const renderBookCashback = () => {
    return (
      <BookCashbackEventSR srFormDetails={srDetails} closeDialogMethod={props.closeDialogMethod} />
    );
  };

  const renderBookEvent = () => {
    return <BookEventSR srFormDetails={srDetails} closeDialogMethod={props.closeDialogMethod} />;
  };

  // const renderCinemaECode = () => {
  //   return <CinemaECodeSR closeDialogMethod={props.closeDialogMethod} />;
  // };

  const renderCommentAndQuery = () => {
    return (
      <CommentAndQuerySR srFormDetails={srDetails} closeDialogMethod={props.closeDialogMethod} />
    );
  };

  const renderComplaint = () => {
    return <ComplaintSR srFormDetails={srDetails} closeDialogMethod={props.closeDialogMethod} />;
  };

  const renderEnquiry = () => {
    return <EnquirySR srFormDetails={srDetails} closeDialogMethod={props.closeDialogMethod} />;
  };

  const typeMapping = {
    'Book Event': renderBookEvent,
    'Book Cashback Event': renderBookCashback,
    'Cinema eCode': renderBookCashback,
    'Film Rental eCode': renderBookCashback,
    CommentAndQuery: renderCommentAndQuery,
    Complaint: renderComplaint,
    Enquiry: renderEnquiry,
    ServiceRequest: renderMembershipServicesSR,
    default: renderMembershipServicesSR,
  };

  return (
    <Fragment>
      <div>{renderForms()}</div>
    </Fragment>
  );
};

export default SRFormSelector;
