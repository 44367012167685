export const setMemberGift = `mutation giftToCustomer($GiftingOfferRequestInput: GiftingOfferRequestInput!) {giftToCustomer(giftingOfferRequest: $GiftingOfferRequestInput) { message  }}`;

export const resetPassword = `mutation forgotPassword($requestDto: ForgotPasswordInput!) {
    forgotPassword(input: $requestDto) {
      code
    }
  }
`;

export const createPaymentDetails = `
  mutation createPaymentDetails($billingAddress: BillingAddressInput!, $membershipId: String, $userId: Long, $memberId: UUID, $bankDetails: BankInput, $cardDetails: CardDetailsInput, $paymentMethod: PaymentMethod!, $primaryMemberExternalRefId: String!, $isDefault: Boolean!, $updateDto: UpdateExistingPaymentVehicleDtoInput, $saveForLater: Boolean!, $isMemberAddressBillingAddress: Boolean!) {
  createPaymentDetails(
    attributes: {paymentMethod: $paymentMethod, bankDetails: $bankDetails, cardDetails: $cardDetails, memberId: $memberId, membershipId: $membershipId, userId: $userId, billingAddress: $billingAddress, primaryMemberExternalRefId: $primaryMemberExternalRefId, isDefault: $isDefault, updateExistingDto: $updateDto, saveForLater: $saveForLater, isMemberAddressBillingAddress: $isMemberAddressBillingAddress}
  ) {
    paymentVehicleId
  }
}
`;

export const reFulFillClaim = `
  mutation reFulFillClaim($reFulfilRequest: ReFulfillRequestInput!) {
    reFulfillClaim(reFulfilRequest: $reFulfilRequest) {
      id
      offerId
      externalId
      offerSource
      offerType
      claimType
      redemptionCode
      expiryDateTime
      claimDateTime
      byAgent
      attributes
      data {
        amount
        emailId
        firstName
        lastName
        address {
          city
          country
          phoneNumber
          postalCode
          postalCodeExt
          line1
          line2
          line3
        }
        reFulfilledForClaimId
        srId
        reason
        additionalComments
      }
    }
  }
`;

export const buyGiftCard = `
  mutation claimOfferAsAgent(
    $offerCompositeKey: OfferCompositeKeyInput!
    $agentId: String!
  ) {
    claimOfferAsAgent(
      offerCompositeKey: $offerCompositeKey
      agentId: $agentId
    ) {
      id
      offerId
      externalId
      offerSource
      offerType
      claimType
      redemptionCode
      expiryDateTime
      claimDateTime
      byAgent
      attributes
      data {
        amount
        emailId
        firstName
        lastName
        address {
          city
          country
          phoneNumber
          postalCode
          postalCodeExt
          line1
          line2
          line3
        }
        reFulfilledForClaimId
        srId
        reason
        additionalComments
      }
    }
  }
`;
