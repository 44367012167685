import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box, Divider, Paper, Stack, Typography, IconButton} from '@mui/material';
import {Wrapper, CustomTextInput, NoSearchResults, SnackBar, CustomDatePicker} from 'Components';
import styles from './styles';
import globalSpacing from 'Theme/Spacing';
import {setTenantDetails} from 'Redux/Slices/login';
import {useSnackBar} from 'Hooks';
import {
  RBSG_TENANT_ID,
  SEARCH_NO_RESULTS_ERROR,
  SR_DASHBOARD_SEARCH_PAGE_SIZE,
  //statusList,
} from 'Utils/constants/genericConstants';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {LoadingButton} from '@mui/lab';
import iconSize from 'Theme/Icon';
import {useCSContext} from 'Context/ContentStackContext';
import {useFormik} from 'formik';
import TRDashboardList from './components/TRDashboardList';
import {gqlOwnerDetails, gqlTransactionDashboardSearch} from 'GQL/MethodsBFF/ServiceRequest';
import {getAllMetadata} from 'Redux/Slices/allMetadata';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';
// import {getAllClaimedOffer} from 'Redux/Slices/transactionDashboardList';
import {v4 as uuidv4} from 'uuid';
import Loading from 'Components/Loading';
import {
  dateConversionToISOFormatIgnoreTimeZone,
  dateConversionToISOFormatIgnoreTimeZoneForEndDate,
} from 'Utils/helpers/DateValidation';
import {MEMBER_SEARCH} from 'Pages/SearchMember/searchConstants';
import {format} from 'date-fns';

const TransactionsDashboard = () => {
  const [error, setError] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [srOwnerList, setOwnerList]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [trTypeMetadata, setTRTypeMetaData]: any = useState([]);
  //const [subTypeMetadata, setSubTypeMetadata]: any = useState({});
  //const [srSubTypeList, setSubTypes]: any[] = useState([]);
  // const [userMap, setUserMap] = useState({});

  const {artifacts} = useCSContext();
  const [multipleResults, setMultipleResults] = useState<any>();
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const clientArray = useSelector((state: any) => state?.login?.loginInfo?.configs);
  //console.log(clientArray);
  const client = clientArray.map((item) => item['name']);
  const loginDetails = useSelector((state: any) => state?.login);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    client: Yup.string().required(),
  });

  const isSearchButtonDisabled = () => {
    const fields = [
      formik.values['transactionId'],
      formik.values['trType'],
      formik.values['trStatus'],
      formik.values['startDate'],
      formik.values['endDate'],
      formik.values['offerType'],
      formik.values['bookingReference'],
      formik.values['transactionDate'],
    ];

    // Count the number of non-empty fields
    const filledFieldsCount = fields.filter(Boolean).length;

    // Disable if less than two fields are filled
    return filledFieldsCount < 1;
  };
  const formik: any = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    initialValues: {
      client: client?.[0],
      id: '',
      trType: '',
      status: '',
      toDate: '',
      fromDate: '',
      offerType: '',
      bookingReference: '',
      transactionDate: '',
    },
    onSubmit: async (values: any) => {
      console.log(values);
      setMultipleResults(null);
      setButtonLoading(true);
      noResults && setNoResults(false);
      let payload = {
        tenantId: loginDetails?.tenantID,
        claimId: values.transactionId ? values.transactionId : null,
        claimType: values.trType,
        status: values.trStatus,
        offerType: values.offerType,
        fromDate: dateConversionToISOFormatIgnoreTimeZone(values['startDate']) || '',
        toDate: dateConversionToISOFormatIgnoreTimeZoneForEndDate(values['endDate']) || '',
        bookingReference: values.bookingReference,
        transactionDate: values['transactionDate']
          ? format(new Date(values['transactionDate']), 'yyyy-MM-dd')
          : '',
        pagingInfo: {
          pageNumber: 0,
          pageSize: SR_DASHBOARD_SEARCH_PAGE_SIZE,
          orderBy: 'id',
          sortOrder: 'descending',
        },
      };
      const headers = {
        'tenant-id': loginDetails?.tenantID,
        // 'tenant-id': 5000005,
        'x-correlation-id': uuidv4(),
        Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
      };
      let response = await gqlTransactionDashboardSearch(payload, headers);
      if (response?.error) {
        setNoResults(true);
        setButtonLoading(false);
      } else if (response && response?.data?.getAllClaimedOffer) {
        let serviceRequestData = response.data.getAllClaimedOffer[0];
        if (serviceRequestData?.claimedOffers?.length > 0 && serviceRequestData !== null) {
          // const usersMappedSRList = mapUsers(serviceRequestData?.claimedOffers);
          setNoResults(false);
          setMultipleResults(serviceRequestData?.claimedOffers);
        } else {
          setNoResults(true);
        }
        //toggleSearchMsg(true);
        setButtonLoading(false);
        setLoading(false);
      } else {
        setError(SEARCH_NO_RESULTS_ERROR);
      }
    },
  });

  // const createUsersMap = (ownerList) => {
  //   const usersMap = {};

  //   (ownerList || []).forEach((owner) => {
  //     usersMap[owner.id] = owner.userName;
  //   });

  //   setUserMap(usersMap);
  // };

  // const mapUsers = (srList) => {
  //   let srListData = srList;

  //   return (srListData || []).map((sr: any) => {
  //     const ownerId = sr?.additionalData?.owner?.id || sr?.owner?.id;
  //     // if (ownerId) {
  //     //   if (!sr?.additionalData?.owner) sr.additionalData.owner = {};
  //     //   sr.additionalData.owner['userName'] = userMap[ownerId];
  //     // }
  //     return {
  //       ...sr,
  //       owner: {
  //         userName: userMap[ownerId],
  //       },
  //     };
  //   });
  // };

  useEffect(() => {
    (async () => {
      await dispatch(setTenantDetails(client[0]));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let promisesToMake = [fetchMetaData(), setSROwnerList()];
      let promises = Promise.all(promisesToMake);
      promises.finally(() => {
        setLoading(false);
      });
    })();
  }, [loginDetails?.tenantID]);

  const clearMessageOnClose = () => {
    setError('');
  };

  const setSROwnerList = async () => {
    try {
      let headers = {
        'tenant-id': !RBSG_TENANT_ID.includes(loginDetails?.tenantID.toString())
          ? loginDetails?.tenantID
          : 'internal',
        'user-access-token': loginDetails?.loginInfo?.access_token,
        'client-access-token': loginDetails?.loginInfo?.service_token,
      };
      let response = await gqlOwnerDetails(headers);
      if (response.error) {
        setOwnerList([]);
      } else {
        setOwnerList(response);
        // createUsersMap(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMetaData = async () => {
    try {
      let response: any = await dispatch(getAllMetadata({})).unwrap();
      if (response?.error) {
        setError(getErrorMsg(response.error));
      } else {
        let srType: any[] = response?.data?.getAllMetadata;
        let subTypes = {};

        const trTypesMap: Map<string, any> = new Map<string, any>();

        srType.forEach((item) => {
          trTypesMap.set(item.name, item);
          if (!subTypes.hasOwnProperty(item.name)) {
            subTypes[item.name] = item.allowedSubTypes;
          } else {
            subTypes[item.name] = Array.from(
              new Set([...subTypes[item.name], ...item.allowedSubTypes]),
            );
          }
        });

        const trTypes: any[] = Array.from(trTypesMap.values());
        const trTypeList = (trTypes || []).map((srType) => srType?.name).sort();
        //setSubTypeMetadata(subTypes);
        setTRTypeMetaData(trTypeList);
        return trTypes;
      }
    } catch (err) {
      console.log(err);
      setError(getErrorMsg(err));
      return err;
    }
  };

  /*const handleSRTypeChange = (e) => {
    const srType = e.target.value;
    let subType = subTypeMetadata[srType];
    setSubTypes(subType || []);
  };*/

  if (loading) return <Loading />;
  return (
    <Wrapper>
      <Paper sx={styles.paper}>
        <Stack spacing={globalSpacing.s} sx={{padding: globalSpacing.m}}>
          <Typography variant="h3">Transaction Dashboard</Typography>
          <Divider light />
          <Box sx={styles.rowContainer}>
            <CustomTextInput
              label="Client"
              name="client"
              inline
              queryTypesMenu={client}
              onChange={(e) => {
                dispatch(setTenantDetails(e.target.value));
                formik.handleChange(e);
              }}
              value={formik.values['client']}
            />
            <CustomTextInput
              label="Transaction Id"
              inline
              name="transactionId"
              queryTypesMenu={srOwnerList}
              onChange={formik.handleChange}
              value={formik.values['transactionId']}
            />
            <CustomTextInput
              label="Transaction Type"
              name="trType"
              inline
              queryTypesMenu={trTypeMetadata}
              onChange={(e) => {
                // handleSRTypeChange(e);
                formik.handleChange(e);
              }}
              value={formik.values['trType']}
            />
            <CustomTextInput
              label="Status"
              name="trStatus"
              inline
              onChange={formik.handleChange}
              value={formik.values['trStatus']}
            />
          </Box>
          <Box sx={styles.rowContainer}>
            <CustomDatePicker
              label="Start Date"
              date={formik.values['startDate'] || null}
              inline
              position="start"
              handleChange={(startDate) => formik.setFieldValue('startDate', startDate)}
              showTime={true}
              reset={() => formik.setFieldValue(['startDate'], null)}
            />
            <CustomDatePicker
              label="End Date"
              date={formik.values['endDate'] || null}
              inline
              position="start"
              handleChange={(endDate) => formik.setFieldValue('endDate', endDate)}
              showTime={true}
              reset={() => formik.setFieldValue(['endDate'], null)}
            />
          </Box>

          <Box sx={styles.rowContainer}>
            <CustomTextInput
              label="Offer Type"
              name="offerType"
              inline
              onChange={formik.handleChange}
              value={formik.values['offerType']}
            />
            <CustomTextInput
              label="Booking Reference"
              name="bookingReference"
              inline
              onChange={formik.handleChange}
              value={formik.values['bookingReference']}
            />
            <CustomDatePicker
              label="Purchase Date"
              date={formik.values['transactionDate'] || null}
              inline
              position="start"
              handleChange={(transactionDate) =>
                formik.setFieldValue('transactionDate', transactionDate)
              }
              reset={() => formik.setFieldValue(['transactionDate'], null)}
            />
          </Box>
          <Box sx={styles.rowContainer}>
            <LoadingButton
              disabled={isSearchButtonDisabled() || buttonLoading}
              onClick={(e) => formik.handleSubmit(e)}
              variant="contained"
              sx={{width: '25%'}}
              loading={buttonLoading}>
              Search
            </LoadingButton>
            <IconButton
              color="primary"
              aria-label="Reset Form"
              onClick={(e) => {
                formik.resetForm({});
                multipleResults && setMultipleResults(null);
                noResults && setNoResults(false);
              }}
              sx={{paddingY: '0rem', marginLeft: globalSpacing.xs}}>
              <img src={artifacts.refresh} style={iconSize} alt="Reset Form" />
            </IconButton>
          </Box>
        </Stack>
      </Paper>
      {noResults && <NoSearchResults CSTexts={MEMBER_SEARCH.no_search_result} />}
      {multipleResults && <TRDashboardList results={multipleResults} />}
      {error && (
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
          clearMessageOnClose={clearMessageOnClose}
        />
      )}
    </Wrapper>
  );
};

export default TransactionsDashboard;
