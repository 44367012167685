import {Box, Button} from '@mui/material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import ResetPassword from './ResetPassword';
import ResetWebenable from './ResetWebenable';

const ResetPasswordAndWebenable = () => {
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const currentMemberData = selectedMember.currentMemberDetails;

  const [isResetPasswordvisible, setResetPasswordVisible] = useState(false);
  const [isResetWebenablevisible, setResetWebenableVisible] = useState(false);
  const hideResetPasswordModal = (e) => {
    setResetPasswordVisible(!isResetPasswordvisible);
  };
  const hideResetWebenableModal = (e) => {
    setResetWebenableVisible(!isResetWebenablevisible);
  };
  const handleResetPasswordClick = () => {
    setResetPasswordVisible(!isResetPasswordvisible);
  };
  const handleResetWebenableClick = () => {
    setResetWebenableVisible(!isResetWebenablevisible);
  };
  return (
    <Box sx={styles.button_main_container}>
      <Button
        disabled={
          currentMemberData?.status?.toUpperCase() !== 'ACTIVE' || !currentMemberData?.isWebEnabled
        }
        onClick={handleResetPasswordClick}>
        Reset Password
      </Button>
      {currentMemberData?.status?.toUpperCase() === 'ACTIVE' && currentMemberData?.isWebEnabled ? (
        <ResetPassword
          openResetPasswordDialog={isResetPasswordvisible}
          isOpenResetPasswordDialog={hideResetPasswordModal}
        />
      ) : (
        ''
      )}

      <Button
        disabled={
          currentMemberData?.status?.toUpperCase() !== 'ACTIVE' || !currentMemberData?.isWebEnabled
        }
        onClick={handleResetWebenableClick}>
        Reset Credentials
      </Button>

      {currentMemberData?.status?.toUpperCase() === 'ACTIVE' && currentMemberData?.isWebEnabled ? (
        <ResetWebenable
          openResetWebenableDialog={isResetWebenablevisible}
          isResetWebenableDialog={hideResetWebenableModal}
          data-test="reset-webenable"
        />
      ) : (
        ''
      )}
    </Box>
  );
};

export default ResetPasswordAndWebenable;

const styles = {
  button_main_container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
};
