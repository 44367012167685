import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import axios from 'axios';
import {globalConfig} from 'Config/config';
import {v4 as uuidv4} from 'uuid';

interface IEvent {
  data: any;
}

const eventAdapter: any = createEntityAdapter<IEvent>({});

const initialState = eventAdapter.getInitialState({
  status: 'idle',
  error: '',
  data: null,
});

export const fetchEvent: any = createAsyncThunk(
  'event/fetchEvent',
  async (data: any, {getState}) => {
    const state: any = getState();
    const {
      BFF: {astBFFapi},
    } = globalConfig._config;
    let userId = state?.selectedMember?.selectedMember?.currentMemberDetails?.userId;
    //need to update userFor data
    // let userId = 83984989;
    const headers = {
      'tenant-id': state?.login.tenantID,
      Authorization: `Bearer ${state?.login.loginInfo?.service_token}`,
      'x-correlation-id': uuidv4(),
    };
    if (data == '') {
      return await axios.get(
        `${astBFFapi}/events?userIdentifierType=userId&userIdentifierValue=${userId}&limit=100`,
        {headers},
      );
    } else {
      return await axios.get(
        `${astBFFapi}/events?userIdentifierType=userId&userIdentifierValue=${userId}&filter=${data}&limit=100`,
        {headers},
      );
    }
  },
);

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    resetEvents: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEvent.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = undefined;
        state.data = action?.payload?.data;
      })
      .addCase(fetchEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
        state.data = undefined;
      });
  },
});

export default eventSlice.reducer;

export const {resetEvents} = eventSlice.actions;
