import {
  // BackButtonWText,
  CustomDatePicker,
  CustomTextInput,
  Loading,
  NoResults,
  TabHeading,
  Wrapper,
} from 'Components';
import React, {Fragment, useEffect, useState} from 'react';
import {TRANSACTIONSLOG} from './transactionLogConstants';
import RefreshButton from 'Components/RefeshButton';
import {useCSContext} from 'Context/ContentStackContext';
import {
  Box,
  // Button,
  Card,
  CardActionArea,
  // CardContent,
  // CardMedia,
  // Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useInfiniteScroll} from 'Hooks';
import globalSpacing from 'Theme/Spacing';
import {useDispatch, useSelector} from 'react-redux';
import {format, isDate} from 'date-fns';
import {DATE_FORMAT} from 'Utils/constants/DateFormat';
import {useLocation} from 'react-router-dom';
import {getAllClaimedOffer} from 'Redux/Slices/transactionDashboardList';
import TransactionDetails from './transactionDetails';

const TabTransactionLogs = () => {
  const {artifacts} = useCSContext();
  const theme = useTheme();
  const location: any = useLocation();
  const state = location?.state;
  const [deatilView, setDetailView] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});
  const loginDetails = useSelector((state: any) => state?.login);
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const dispatch = useDispatch();
  const {txnOfferTypes} = useSelector((state: any) => state?.login?.tenantConfig);

  const txn = ['Select All', ...txnOfferTypes.split(',').map((offer) => offer.trim())];
  const [filterByType, setFilterByType] = useState(txn[0]);
  const [filterByDate, setFilterByDate] = useState(null);

  const [loading, setLoading] = useState(true);
  const [refreshFetch, setRefreshFetch] = useState(false);
  // const [error, setError] = useState(null);
  // const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  // const [transactions, setTransactions] = useState<any[]>([]);
  const [{resetPage}] = useInfiniteScroll();
  const transactionList = useSelector(
    (state: any) => state?.transactionLogList?.transactionslogList,
  );

  useEffect(() => {
    if (state?.selectedClaim?.id) {
      // handleSRRowClick();
      // showTransactionDetails(state?.selectedClaim);
      setDetailView(true);
      setSelectedTransaction(state?.selectedClaim);
      state.selectedClaim = null;
    }
  }, [state]);

  // const transactionList = [];
  useEffect(() => {
    if (refreshFetch) {
      (async () => {
        setLoading(true);
        let payload = {
          tenantId: loginDetails?.tenantID,
          userId: selectedMember?.currentMemberDetails?.userId,
          customerId:
            selectedMember?.currentMemberDetails?.memberId ??
            selectedMember?.currentMemberDetails?.userId,
          pageNumber: 0,
          pageSize: 100,
        };
        await dispatch(getAllClaimedOffer(payload)).unwrap();
        setRefreshFetch(false);
        setLoading(false);
      })();
    }
  }, [refreshFetch]);

  const refresh = () => {
    setRefreshFetch(true);
    resetPage();
    setFilterByType(txn[0]);
    setFilterByDate(null);
  };

  const renderLogCard = () => {
    if (transactionList?.claimedOffers?.length) {
      return transactionList?.claimedOffers?.map((item: any, index: number) => (
        <Card sx={{border: 1, borderColor: theme.palette.grey.A200, mt: globalSpacing.s}}>
          <CardActionArea
            sx={{padding: globalSpacing.sm}}
            onClick={() => {
              setDetailView(true);
              setSelectedTransaction(item);
            }}
            disableRipple

            // disabled={disabled}
          >
            <Typography
              color="primary"
              variant="h4"
              sx={{
                // maxWidth: '40%',
                wordBreak: 'break-word',
                marginBottom: globalSpacing.xxs,
              }}>
              {item?.offerType == 'EVENT'
                ? item?.data?.claimDetails?.eventName
                : item?.data?.claimDetails?.orderData?.data?.orderId}
            </Typography>

            <Box flexDirection="row" display="flex" gap="5%">
              {/* claim and expiry */}
              <Box
                flexDirection="column"
                display={'flex'}
                width="40%"
                justifyContent="space-between">
                <Typography variant="h6" component="h5">
                  <strong>{TRANSACTIONSLOG.card_field_map.claimDate} </strong>

                  {item.claimDateTime &&
                    isDate(new Date(item.claimDateTime)) &&
                    format(new Date(item.claimDateTime), DATE_FORMAT.dateFormat)}
                </Typography>
                {/* TODO this field might change name in backend to offerenddate for transactions*/}

                <Typography variant="h6" component="h5">
                  <strong>{TRANSACTIONSLOG.card_field_map.expiryDate} </strong>
                  {item.expiryDateTime &&
                    isDate(new Date(item.expiryDateTime)) &&
                    format(new Date(item.expiryDateTime), DATE_FORMAT.dateFormat)}
                </Typography>
              </Box>
              {/* vendor name */}
              <Box flexDirection="column" display={'flex'} width="20%">
                <Typography variant="subtitle1">
                  {item?.data?.claimDetails?.merchantName}
                </Typography>
              </Box>

              {/* type and status */}
              <Box flexDirection="column" display={'flex'}>
                <Typography variant="h6" component="h5">
                  {TRANSACTIONSLOG.card_field_map.type}{' '}
                  <strong style={{textTransform: 'capitalize'}}>{item.offerType}</strong>
                </Typography>

                <Typography variant="h6" component="h5">
                  {TRANSACTIONSLOG.card_field_map.status}{' '}
                  <strong style={{textTransform: 'capitalize'}}>{item.status}</strong>
                  {/* {item.status} */}
                </Typography>
              </Box>
            </Box>
          </CardActionArea>
        </Card>
      ));
    } else {
      return <NoResults text={TRANSACTIONSLOG.no_results_text} />;
    }
  };

  useEffect(() => {
    if (!filterByDate) {
      if (filterByType === txn[0]) {
        (async () => {
          setLoading(true);
          let payload = {
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            pageNumber: 0,
            pageSize: 100,
          };
          await dispatch(getAllClaimedOffer(payload)).unwrap();
          setLoading(false);
        })();
      } else {
        //if filter != show all
        (async () => {
          setLoading(true);
          let payload = {
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            offerType: filterByType,
            pageNumber: 0,
            pageSize: 100,
          };
          await dispatch(getAllClaimedOffer(payload)).unwrap();
          setLoading(false);
        })();
      }
    } else {
      //Filter by date only (type = show all)
      if (filterByDate && filterByType === txn[0]) {
        //filterByDate (it's already formatted in datepicker onchange)
        (async () => {
          setLoading(true);
          let payload = {
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            transactionDate: format(new Date(filterByDate), 'yyyy-MM-dd'),
            pageNumber: 0,
            pageSize: 100,
          };
          await dispatch(getAllClaimedOffer(payload)).unwrap();
          setLoading(false);
        })();
      }
      if (filterByDate && filterByType !== txn[0]) {
        //filterByDate(it's already formatted in datepicker onchange)
        (async () => {
          setLoading(true);
          let payload = {
            tenantId: loginDetails?.tenantID,
            userId: selectedMember?.currentMemberDetails?.userId,
            customerId:
              selectedMember?.currentMemberDetails?.memberId ??
              selectedMember?.currentMemberDetails?.userId,
            transactionDate: format(new Date(filterByDate), 'yyyy-MM-dd'),
            offerType: filterByType,
            pageNumber: 0,
            pageSize: 100,
          };
          await dispatch(getAllClaimedOffer(payload)).unwrap();
          setLoading(false);
        })();
      }
    }
  }, [filterByType, filterByDate]);

  return (
    <>
      <Wrapper customStyle={{width: '95%'}}>
        {!deatilView ? (
          <Fragment>
            <TabHeading
              data={TRANSACTIONSLOG}
              addOn={<RefreshButton image={artifacts.refresh} refresh={refresh} />}
            />
            <Box
              sx={{
                marginTop: globalSpacing.sm,
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <CustomTextInput
                // disabled={filterDisabled}
                inline
                label={TRANSACTIONSLOG.filter_label}
                name="filter"
                filterMenu={txn}
                value={filterByType}
                onChange={(e) => {
                  setFilterByType(e.target.value);
                  resetPage();
                }}
              />
              <CustomDatePicker
                date={filterByDate}
                handleChange={(searchDate) => {
                  setFilterByDate(searchDate);
                  resetPage();
                }}
                label={TRANSACTIONSLOG.date_search_label}
                flex={0.3}
              />
            </Box>

            {/* FIlter option  */}

            {loading ? <Loading isOpen={loading} /> : ''}

            <Box overflow="overflow">
              {/* {!loading ? (
              error ? (
                <SnackBar
                  errorMessage={error}
                  isSnackBarOpen={isSnackBarOpen}
                  closeSnackBar={closeSnackBar}
                />
              ) : transactionList.length ? (
                <InfiniteScrollerCustom
                  dataLength={transactions.length}
                  next={() => nextPage()}
                  hasMore={transactionList.length < totalResults}>
                  <Stack
                    spacing={globalSpacing.s}
                    mt={globalSpacing.sm}
                    boxShadow="none"
                    tabIndex={0}>
                    {transactions?.map((transaction, index) => (
                      <TableItemCard
                        key={index}
                        item={transaction}
                        toggleView={toggleDetailView}
                        handleItem={handleItem}
                      />
                    ))}
                  </Stack>
                </InfiniteScrollerCustom>
              ) : (
                <NoResults text={TRANSACTIONSLOG.no_results_text} />
              )
            ) : null} */}

              <Box>{renderLogCard()}</Box>
            </Box>
          </Fragment>
        ) : (
          <>
            <TransactionDetails
              selectedTransaction={selectedTransaction}
              toggleView={() => {
                setDetailView(false);
              }}
            />
          </>
        )}
      </Wrapper>
    </>
  );
};

export default TabTransactionLogs;
